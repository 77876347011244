import { LEAGUES_ATTRIBUTE_DETAIL,TEAM_ATTRIBUTE_DETAIL, LEAGUES_CUP_ATTRIBUTE_DETAIL,TEAM_CUP_ATTRIBUTE_DETAIL } from "../constants/types";
 
const initialStates = {LEAGUES_ATTRIBUTE_DETAIL: ""};
export const useLeagueReducers = (state = initialStates , action) =>{
    //console.log('data action' , state.LEAGUES_ATTRIBUTE_DETAIL)
    switch(action.type){
        case LEAGUES_ATTRIBUTE_DETAIL:
        return{
            ...state,
            LEAGUES_ATTRIBUTE_DETAIL : action.payload
        }
        default : return state
        
    }
}

const initialState = {TEAM_ATTRIBUTE_DETAIL:""};
export const teamLeagueReducers = (state = initialState , action) => {
     switch(action.type){
        case TEAM_ATTRIBUTE_DETAIL:
            return{
                ...state,
                TEAM_ATTRIBUTE_DETAIL : action.payload
            }
            default : return state
     }
}


const initialCupLeagueStates = {LEAGUES_CUP_ATTRIBUTE_DETAIL: ""};
export const useLeagueCupReducers = (state = initialCupLeagueStates , action) =>{
    //console.log('data action' , state.LEAGUES_ATTRIBUTE_DETAIL)
    switch(action.type){
        case LEAGUES_CUP_ATTRIBUTE_DETAIL:
        return{
            ...state,
            LEAGUES_CUP_ATTRIBUTE_DETAIL : action.payload
        }
        default : return state
        
    }
}

const initialCupTeamState = {TEAM_CUP_ATTRIBUTE_DETAIL:""};
export const teamLeagueCupReducers = (state = initialCupTeamState , action) => {
     switch(action.type){
        case TEAM_CUP_ATTRIBUTE_DETAIL:
            return{
                ...state,
                TEAM_CUP_ATTRIBUTE_DETAIL : action.payload
            }
            default : return state
     }
}
