export const USER_ATTRIBUTE_DETAIL = "USER_ATTRIBUTE_DETAIL";
export const CHIP_ATTRIBUTE_DETAIL = "CHIP_ATTRIBUTE_DETAIL";
export const LEAGUES_ATTRIBUTE_DETAIL = "LEAGUES_ATTRIBUTE_DETAIL";
export const TEAM_ATTRIBUTE_DETAIL = "TEAM_ATTRIBUTE_DETAIL";
export const LEAGUES_CUP_ATTRIBUTE_DETAIL = "LEAGUES_CUP_ATTRIBUTE_DETAIL";
export const TEAM_CUP_ATTRIBUTE_DETAIL = "TEAM_CUP_ATTRIBUTE_DETAIL";
export const ADMIN_USER_ATTRIBUTE_DETAIL = "ADMIN_USER_ATTRIBUTE_DETAIL";
export const RIVALARY_ATTRIBUTE_DETAIL = "RIVALARY_ATTRIBUTE_DETAIL";
export const RIVALARY_UPDATE_ATTRIBUTE_DETAIL = "RIVALARY_UPDATE_ATTRIBUTE_DETAIL";

export const SET_ADS_BID_DATE = "SET_ADS_BID_DATE"
export const SET_ADS_BID_DATE_ERROR = "SET_ADS_BID_DATE_ERROR"
export const ADS_SLOT_LIST = "ADS_SLOT_LIST"
export const ADS_SLOT_LIST_ERROR = "ADS_SLOT_LIST_ERROR"
export const SET_SLOT_MIN_AMOUNT = "SET_SLOT_MIN_AMOUNT"
export const SET_SLOT_MIN_AMOUNT_ERROR = "SET_SLOT_MIN_AMOUNT_ERROR"
export const ADS_COMPANY_LIST = "ADS_COMPANY_LIST"
export const ADS_COMPANY_LIST_ERROR = "ADS_COMPANY_LIST_ERROR"
export const ADS_COMPANY_DETAIL = "ADS_COMPANY_DETAIL"
export const ADS_COMPANY_DETAIL_ERROR = "ADS_COMPANY_DETAIL_ERROR"
export const APPROVE_BID_AMOUNT = "APPROVE_BID_AMOUNT"
export const APPROVE_BID_AMOUNT_ERROR = "APPROVE_BID_AMOUNT_ERROR"
export const ADS_ALLOTED_SLOT_LIST = "ADS_ALLOTED_SLOT_LIST"
export const ADS_ALLOTED_SLOT_LIST_ERROR = "ADS_ALLOTED_SLOT_LIST_ERROR"
export const NOTIFICATION_LIST = "NOTIFICATION_LIST"
export const NOTIFICATION_LIST_ERROR = "NOTIFICATION_LIST_ERROR"
export const REMOVE_SLOT_IMAGE = "REMOVE_SLOT_IMAGE"
export const REMOVE_SLOT_IMAGE_ERROR = "REMOVE_SLOT_IMAGE_ERROR"
export const UPLOAD_SLOT_IMAGE = "UPLOAD_SLOT_IMAGE"
export const UPLOAD_SLOT_IMAGE_ERROR = "UPLOAD_SLOT_IMAGE_ERROR"
export const SEASON_LIST = "SEASON_LIST"
export const SEASON_LIST_ERROR = "SEASON_LIST_ERROR"
export const SUBSCRIBERS_LIST = "SUBSCRIBERS_LIST"
export const SUBSCRIBERS_LIST_ERROR = "SUBSCRIBERS_LIST_ERROR"



