import {
    SET_ADS_BID_DATE,
    SET_SLOT_MIN_AMOUNT,
    ADS_SLOT_LIST,
    ADS_COMPANY_LIST,
    ADS_COMPANY_DETAIL,
    APPROVE_BID_AMOUNT,
    ADS_ALLOTED_SLOT_LIST,
    REMOVE_SLOT_IMAGE,
    UPLOAD_SLOT_IMAGE
} from '../constants/types';

const initialStates = {
    SET_ADS_BID_DATE: {},
    SET_SLOT_MIN_AMOUNT: {},
    ADS_SLOT_LIST: {},
    ADS_COMPANY_LIST: {},
    ADS_COMPANY_DETAIL: {},
    APPROVE_BID_AMOUNT: {},
    ADS_ALLOTED_SLOT_LIST: {},
    REMOVE_SLOT_IMAGE: {},
    UPLOAD_SLOT_IMAGE: {}
};

export const adsManageReducer = (state = initialStates, action) => {

    switch (action.type) {
        case SET_ADS_BID_DATE:
            return {
                ...state,
                SET_ADS_BID_DATE: action.payload
            }
        case SET_SLOT_MIN_AMOUNT:
            return {
                ...state,
                SET_SLOT_MIN_AMOUNT: action.payload
            }
        case ADS_SLOT_LIST:
            return {
                ...state,
                ADS_SLOT_LIST: action.payload
            }
        case ADS_COMPANY_LIST:
            return {
                ...state,
                ADS_COMPANY_LIST: action.payload
            }
        case ADS_COMPANY_DETAIL:
            return {
                ...state,
                ADS_COMPANY_DETAIL: action.payload
            }
        case APPROVE_BID_AMOUNT:
            return {
                ...state,
                APPROVE_BID_AMOUNT: action.payload
            }
        case ADS_ALLOTED_SLOT_LIST:
            return {
                ...state,
                ADS_ALLOTED_SLOT_LIST: action.payload
            }
        case REMOVE_SLOT_IMAGE:
            return {
                ...state,
                REMOVE_SLOT_IMAGE: action.payload
            }
        case UPLOAD_SLOT_IMAGE:
            return {
                ...state,
                UPLOAD_SLOT_IMAGE: action.payload
            }
        default: return state
    }
}