import {USER_ATTRIBUTE_DETAIL,ADMIN_USER_ATTRIBUTE_DETAIL,SUBSCRIBERS_LIST } from '../constants/types';

const initialStates = { USER_ATTRIBUTE_DETAIL:""};

export const UserAttributeReducer = (state = initialStates, action) =>{
    //console.log("stateISS",state);
     switch(action.type){
        case USER_ATTRIBUTE_DETAIL:
            return{
                ...state,
                USER_ATTRIBUTE_DETAIL: action.payload
            }
            default: return state
     }
}

const initialStatess = { ADMIN_USER_ATTRIBUTE_DETAIL:""};

export const AdminUserAttributeReducer = (state = initialStatess, action) =>{
    //console.log("stateISS",state);
     switch(action.type){
        case ADMIN_USER_ATTRIBUTE_DETAIL:
            return{
                ...state,
                ADMIN_USER_ATTRIBUTE_DETAIL: action.payload
            }
            default: return state
     }
}

export const AdminSubscriptionReducer = (state = initialStatess, action) =>{
    //console.log("stateISS",state);
     switch(action.type){
        case SUBSCRIBERS_LIST:
            return{
                ...state,
                SUBSCRIBERS_LIST: action.payload
            }
            default: return state
     }
}
