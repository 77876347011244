import { combineReducers } from "redux";
import { UserAttributeReducer,AdminUserAttributeReducer,AdminSubscriptionReducer } from './userReducers';
import { ChipAttributeReducer } from './chipReducer';
import {useLeagueReducers,teamLeagueReducers,useLeagueCupReducers , teamLeagueCupReducers} from './leagueReduces';
import { useRivalaryReducers,useRivalaryUpdateReducers } from './rivalaryReducer';
import { adsManageReducer } from './adsReducer';
import { seasonManageReducer } from './seasonReducer';
import { notificationReducer } from './notificationReducer';

const RootReducer = combineReducers({UserAttributeReducer ,AdminUserAttributeReducer,AdminSubscriptionReducer, ChipAttributeReducer, useLeagueReducers, 
    teamLeagueReducers, useLeagueCupReducers , teamLeagueCupReducers, useRivalaryReducers,useRivalaryUpdateReducers,adsManageReducer,seasonManageReducer, notificationReducer})

export default RootReducer