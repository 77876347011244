import { RIVALARY_ATTRIBUTE_DETAIL,RIVALARY_UPDATE_ATTRIBUTE_DETAIL } from "../constants/types"
const initialStates = { RIVALARY_ATTRIBUTE_DETAIL:""};
export const useRivalaryReducers = (state = initialStates,action) =>{
      switch(action.type){
        case RIVALARY_ATTRIBUTE_DETAIL:
            return{
                ...state,
                RIVALARY_ATTRIBUTE_DETAIL : action.payload
            }
            default : return state
      }
}

const initialState = { RIVALARY_UPDATE_ATTRIBUTE_DETAIL:"" }
export const useRivalaryUpdateReducers = (state = initialState,action) => {
     switch(action.type){
        case RIVALARY_UPDATE_ATTRIBUTE_DETAIL:
            return{
                ...state,
                RIVALARY_UPDATE_ATTRIBUTE_DETAIL : action.payload
            }
            default: return state
     }
}