import {
    SEASON_LIST
} from '../constants/types';

const initialStates = {
    SEASON_LIST: []   
};

export const seasonManageReducer = (state = initialStates, action) => {

    switch (action.type) {
        case SEASON_LIST:
            return {
                ...state,
                SEASON_LIST: action.payload
            }       
        default: return state
    }
}