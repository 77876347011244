import {
    NOTIFICATION_LIST
} from '../constants/types';

const initialStates = {
    NOTIFICATION_LIST: {}
};

export const notificationReducer = (state = initialStates, action) => {

    switch (action.type) {

        case NOTIFICATION_LIST:
            return {
                ...state,
                NOTIFICATION_LIST: action.payload
            }
        default: return state
    }
}