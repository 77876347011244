import { CHIP_ATTRIBUTE_DETAIL } from '../constants/types';

const initialStates = { CHIP_ATTRIBUTE_DETAIL:"" };

export const ChipAttributeReducer = (state = initialStates, action) =>{
    //console.log("stateISS",state);
     switch(action.type){
        case CHIP_ATTRIBUTE_DETAIL:
            return{
                ...state,
                CHIP_ATTRIBUTE_DETAIL: action.payload
            }
        default: return state
     }
}