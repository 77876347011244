import 'bootstrap/dist/css/bootstrap.min.css';
import "primereact/resources/themes/lara-light-indigo/theme.css";  
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";  
import './App.scss';
import React from 'react';
import { BrowserRouter as Router, Route,  Routes, Switch,  } from 'react-router-dom';
import { Provider } from 'react-redux'
import {toast as ToastR , ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Store } from '../src/views/redux/store'

const Layout = React.lazy(() => import('./containers/TheLayout'));
const AuthLayout = React.lazy(() => import('./containers/AuthLayout'));

function App() {
  return (
    
    <Provider store={Store}>
      <ToastContainer />
    <Router>
          <React.Suspense>
              <Switch>
                  <Route exact path="/" name="Login" render={(props) => <AuthLayout {...props} />} />
                  <Route exact path="/signup" name="Signup" render={(props) => <AuthLayout {...props} />} />
                  <Route exact path="/verification" name="Verification" render={(props) => <AuthLayout {...props} />} />
                  <Route exact path="/complete-setup" name="CompleteSetup" render={(props) => <AuthLayout {...props} />} />
                  <Route exact path="/dashboard" name="Dashboard" render={(props) => <Layout {...props} />} />
                  <Route exact path="/fr-league" name="FrLeague" render={(props) => <Layout {...props} />} />
                  <Route exact path="/uefa-cups" name="UefaLeague" render={(props) => <Layout {...props} />} />
                  <Route exact path="/user_list" name="UserList" render={(props) => <Layout {...props} />} /> 
                  <Route exact path="/admin_user_list" name="AdminUserList" render={(props) => <Layout {...props} />} />
                  <Route exact path="/league_team_list" name="TeamList" render={(props) => <Layout {...props} />} /> 
                  <Route exact path="/cup_team_list" name="CupTeamList" render={(props) => <Layout {...props} />} />
                  <Route exact path="/user_create_event" name="UserCreateEventList" render={(props) => <Layout {...props} />} />  
                  <Route exact path="/event_joined_user_list" name="EventJoinedUserList" render={(props) => <Layout {...props} />} />  
                   
                  <Route exact path="/add-league" name="AddFrLeague" render={(props) => <Layout {...props} />} />
                  <Route exact path="/chips" name="Chips" render={(props) => <Layout {...props} />} />
                  <Route exact path="/frleaguerule" name="FrLeaguerule" render={(props) => <Layout {...props} />} />
                  <Route exact path="/uefaleaguerule" name="UefaLeaguerule" render={(props) => <Layout {...props} />} />
                  <Route exact path="/deposits" name="Deposits" render={(props) => <Layout {...props} />} />
                  <Route exact path="/withdrawals" name="Withdrawals" render={(props) => <Layout {...props} />} />
                  <Route exact path="/payouts" name="Payouts" render={(props) => <Layout {...props} />} />
                  <Route exact path="/payout-list" name="PayoutList" render={(props) => <Layout {...props} />} />
                  <Route exact path="/frleague-game" name="FrleagueGame" render={(props) => <Layout {...props} />} />
                  <Route exact path="/campion-match" name="CampionMatch" render={(props) => <Layout {...props} />} />
                  <Route exact path="/europa-match" name="EuropaMatch" render={(props) => <Layout {...props} />} />
                  <Route exact path="/create-deadline/:mode" name="CreateDeadline" render={(props) => <Layout {...props} />} />
                  <Route exact path="/Frclub-category" name="FrclubCategory" render={(props) => <Layout {...props} />} />
                  <Route exact path="/Uefacup-sedingpot" name="UefasedingPot" render={(props) => <Layout {...props} />} />
                  <Route exact path="/rivalry" name="Rivalry" render={(props) => <Layout {...props} />} />
                  <Route exact path="/chip-point" name="Chippoint" render={(props) => <Layout {...props} />} />
                  <Route exact path="/goals" name="Goals" render={(props) => <Layout {...props} />} />
                  <Route exact path="/cards" name="Cards" render={(props) => <Layout {...props} />} />
                  <Route exact path="/news" name="NewsMedia" render={(props) => <Layout {...props} />} />
                  <Route exact path="/media" name="Media" render={(props) => <Layout {...props} />} />
                  <Route exact path="/ads" name="Ads" render={(props) => <Layout {...props} />} />
                  <Route exact path="/manage-add" name="MangageAds" render={(props) => <Layout {...props} />} />
                  <Route exact path="/push-notifications" name="PushNotification" render={(props) => <Layout {...props} />} />
                  <Route exact path="/private-notifications" name="PrivateNotification" render={(props) => <Layout {...props} />} />
                  <Route exact path="/auto-draw" name="AutoDraw" render={(props) => <Layout {...props} />} />
                  <Route exact path="/customer-service" name="CustomerService" render={(props) => <Layout {...props} />} />
                  <Route exact path="/season-management" name="SeasonManagement" render={(props) => <Layout {...props} />} />
                  <Route exact path="/terms-condition" name="TermsCondition" render={(props) => <Layout {...props} />} />
                  <Route exact path="/surveys" name="Surveys" render={(props) => <Layout {...props} />} />
                  <Route exact path="/privacy-policy" name="PrivacyPolicy" render={(props) => <Layout {...props} />} />
                  <Route exact path="/notification-view" name="NotificationView" render={(props) => <Layout {...props} />} />
                  <Route exact path="/edit-deadline" name="EditDeadline" render={(props) => <Layout {...props} />} />
                  <Route exact path="/subscribe-user" name="SubscribeUser" render={(props) => <Layout {...props} />} />
              </Switch>
          </React.Suspense>
    </Router>
    </Provider>
  );
}

export default App;
